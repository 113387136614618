@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Gothampro';
  src: url('./assets/fonts/gothampro-black.ttf') format('ttf'),
       url('./assets/fonts/gothampro-black.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GothamproBold';
  src: url('./assets/fonts/GOTHAPROBOL.OTF') format('otf'),
       url('./assets/fonts/GOTHAPROBOL.OTF') format('otf');
  font-weight: bold;
  font-style: normal;
}


* {
  box-sizing: border-box;
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
  
}

html{
  scroll-behavior: smooth;
}

body{
  font-family: var(--gotham);
  font-size: 16px;
  color: #202022;
  line-height: 2.4rem;
  overflow-x:hidden;
}

.container{
  max-width: 1200px;
  padding-left:20px;
  padding-right:20px;
  width: 100%;
  margin: 0 auto;
}

.gotham {
  font-family: var(--gotham);
}
.hero-bg{
  background-image: url("./assets/images/hero-bg.jpg");
  background-position:top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 92vh;
}

@media (max-width: 1424px) {
  .hero-bg {
    background-size:auto ;
    height: 90vh; /* Increase height on medium screens */
  }
}

@media (max-width: 768px) {
  .hero-bg {
    height: 70vh; 
  }
}

@media (max-width: 640px) {
  .hero-bg {
    height: auto; /* Adjust height for very small screens */
    min-height: 300px;
    background-position:top left;
  }
}

/* TODO:check once again */
.description-one{
  background-image: url("./assets/images/bg-green.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}

.description-two{
  background-image: url("./assets/images/bg-orange.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}

table {
  border-collapse: collapse;
  width: 100%;
}

.about-bg{
  background-image: url("./assets/images/bgpg3.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}
.about-img{
  background-image: url("./assets/images/bgpg3.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}
